<template>
  <v-row class="stop-info-panel">
    <v-col cols="12">
      <v-card class="pt-2">
        <v-card-text class="d-flex flex-wrap px-4">
          <v-row>
            <v-col>
              <MglMap
                ref="map"
                :access-token="$config.mapbox.accessToken"
                :track-resize="true"
                :touch-zoom-rotate="true"
                :scroll-zoom="true"
                :attribution-control="false"
                :map-style="$config.mapbox.style"
                :zoom="$config.mapbox.zoomForInfoPanel"
                :center="[stop.stop_lon, stop.stop_lat]"
              >
                <template>
                  <MglMarker :coordinates="[stop.stop_lon, stop.stop_lat]">
                    <v-icon slot="marker" size="25" color="#FF7800" class="map-icon">
                      {{ icons.mdiCircleSlice8 }}
                    </v-icon>
                  </MglMarker>
                </template>
              </MglMap>
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-text>
          <h2 class="text-xl font-weight-semibold mb-2">Details</h2>
          <v-divider class="mt-2 mb-2"></v-divider>
          <v-list>
            <v-list-item dense class="px-0 mb-n2">
              <v-row>
                <v-col class="d-flex" cols="4">
                  <span class="font-weight-medium me-2">ID:</span>
                </v-col>
                <v-col cols="8">
                  <span class="text--secondary">{{ stop.stop_id }}</span>
                </v-col>
              </v-row>
            </v-list-item>

            <v-list-item dense class="px-0 mb-n2">
              <v-row>
                <v-col class="d-flex" cols="4">
                  <span class="font-weight-medium me-2">Description:</span>
                </v-col>
                <v-col cols="8">
                  <span class="text--secondary">{{ stop.stop_desc }}</span>
                </v-col>
              </v-row>
            </v-list-item>

            <v-list-item dense class="px-0 mb-n2">
              <v-row>
                <v-col class="d-flex" cols="4">
                  <span class="font-weight-medium me-2">Location:</span>
                </v-col>
                <v-col cols="8">
                  <span class="text--secondary">{{ stop.stop_lat }} / {{ stop.stop_lon }}</span>
                </v-col>
              </v-row>
            </v-list-item>

            <v-list-item dense class="px-0 mb-n2">
              <v-row>
                <v-col class="d-flex" cols="4">
                  <span class="font-weight-medium me-2">Fare Zone:</span>
                </v-col>
                <v-col cols="8">
                  <span class="text--secondary">{{ stop.zone_id }}</span>
                </v-col>
              </v-row>
            </v-list-item>
          </v-list>
        </v-card-text>
      </v-card>
      <v-card class="mt-5">
        <v-card-text>
          <h2 class="text-center text-xl font-weight-semibold mb-2">
            Full stop announcement
            <v-btn v-if="!isPreviewing" class="mx-2" fab dark small color="primary" @click="fullStopPreview">
              <v-icon x-large>
                {{ icons.mdiPlayCircleOutline }}
              </v-icon>
            </v-btn>
            <v-btn v-else class="mx-2" fab dark small color="accent">
              <v-icon x-large>
                {{ icons.mdiPlayCircleOutline }}
              </v-icon>
            </v-btn>
          </h2>
          <v-divider class="mt-2 mb-2"></v-divider>
          <v-alert border="left" class="stop-full-annoucement" colored-border text color="primary">
            <router-link v-if="globalSettings.intro_tts" :to="{ name: 'next-stop-settings' }" class="nav-link">
              <v-tooltip bottom class="text--primary">
                <template #activator="{ on, attrs }">
                  <v-chip v-bind="attrs" color="primary" link v-on="on">
                    {{ globalSettings.intro_tts }}
                  </v-chip>
                </template>
                <span>Change this introduction in the setting page</span>
              </v-tooltip>
            </router-link>

            <v-tooltip v-if="globalSettings.intro_tts" bottom class="text--primary">
              <template #activator="{ on, attrs }">
                <v-chip v-bind="attrs" color="info" v-on="on">
                  {{ stopName }}
                </v-chip>
              </template>
              <span>You can customize the name of the stop on this page</span>
            </v-tooltip>

            <router-link v-if="transfersIntro && transfersMsg" :to="{ name: 'next-stop-settings' }">
              <v-tooltip bottom class="text--primary">
                <template #activator="{ on, attrs }">
                  <v-chip v-bind="attrs" color="primary" link v-on="on">
                    {{ transfersIntro }}
                  </v-chip>
                </template>
                <span>
                  You can customise the transfer announcement of the global transfer annoucement on the setting page
                </span>
              </v-tooltip>
            </router-link>

            <v-tooltip v-if="transfersMsg" bottom class="text--primary">
              <template #activator="{ on, attrs }">
                <v-chip v-bind="attrs" color="info" v-on="on">
                  {{ transfersMsg }}
                </v-chip>
              </template>
              <span> You can set transfer route on this page </span>
            </v-tooltip>

            <router-link v-if="stop.config.additional_tts" :to="{ name: 'next-stop-settings' }" class="nav-link">
              <v-tooltip bottom class="text--primary">
                <template #activator="{ on, attrs }">
                  <v-chip v-bind="attrs" color="primary" link v-on="on">
                    {{ globalSettings.additional_intro_tts }}
                  </v-chip>
                </template>
                <span>Change this introduction in the setting page</span>
              </v-tooltip>
            </router-link>

            <v-tooltip v-if="stop.config.additional_tts" bottom class="text--primary">
              <template #activator="{ on, attrs }">
                <v-chip v-bind="attrs" color="info" v-on="on">
                  {{ stop.config.additional_tts }}
                </v-chip>
              </template>
              <span>You can customize the additional annoucement on this page</span>
            </v-tooltip>
          </v-alert>
        </v-card-text>
      </v-card>
      <v-card class="mt-5">
        <v-card-text>
          <h2 class="text-center text-xl font-weight-semibold mb-2">Routes</h2>
          <v-divider class="mt-2 mb-4"></v-divider>
          <v-data-table
            :headers="routeTableHeaders"
            :items="relatedRoutes"
            item-key="route_id"
            hide-default-footer
            hide-default-header
            disable-pagination
            dense
          >
            <template #[`item.route_id`]="{ item }">
              <router-link
                class="router-link-route"
                :to="{ name: 'next-stop-route-config', params: { id: item.route_id } }"
              >
                <h2 class="text-sm font-weight-bold mb-1 mt-1">
                  <route-badge
                    :route="item"
                    :size="38"
                    avatar-class="vehicle-avatar"
                    text-class="text-1xl"
                  ></route-badge>
                </h2>
              </router-link>
            </template>

            <template #[`item.route_long_name`]="{ item }">
              <router-link
                class="router-link-route"
                :to="{ name: 'next-stop-route-config', params: { id: item.route_id } }"
              >
                <span class="text-capitalize font-weight-semibold text--primary">
                  {{ item.route_long_name }}
                </span>
              </router-link>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { mapState } from "vuex"
import { mdiOpenInNew, mdiArrowLeft, mdiCircleSlice8, mdiPlayCircleOutline } from "@mdi/js"
import { MglMap, MglMarker } from "vue-mapbox"
import store from "@/store"
import RouteBadge from "@/components/route/Badge.vue"
import "mapbox-gl/dist/mapbox-gl.css"

export default {
  name: "StopInfoPanel",
  components: {
    MglMap,
    MglMarker,
    RouteBadge,
  },
  data() {
    return {
      isPreviewing: false,
      routeTableHeaders: [
        { text: "ID", value: "route_id", sortable: false },
        { text: "Description", value: "route_long_name", sortable: false },
      ],
    }
  },
  computed: {
    ...mapState({
      routes: state => state.gtfs.routes,
      stop: state => state.selected_stop,
      globalSettings: state => state.settings,
    }),
    relatedRoutes() {
      return this.stop.routes.map(id => this.getRoute(id))
    },
    stopName() {
      return this.stop.config.stop_tts ? this.stop.config.stop_tts : this.stop.stop_name
    },
    transfersIntro() {
      return this.globalSettings.transfer_tts
    },
    transfersMsg() {
      if (!this.stop.config.transfers_enabled) {
        return null
      }

      if (this.stop.config.custom_transfer_tts) {
        return this.stop.config.custom_transfer_tts
      }

      const transfers = this.stop.config.transfers.map(t => t.route_id)

      return transfers
        .map(transfer => {
          const route = this.routes.find(r => r.route_id === parseInt(transfer, 10))

          return route ? route.route_short_name : null
        })
        .join(", ")
    },
  },
  methods: {
    getRoute(routeId) {
      return this.routes.find(route => route.route_id === routeId)
    },
    fullStopPreview() {
      this.isPreviewing = true
      store
        .dispatch("fullStopPreview", { stopId: this.stop.stop_id })
        .then(() => {
          this.isPreviewing = false
        })
        .catch(() => {
          this.isPreviewing = false
        })
    },
  },
  setup() {
    return {
      icons: {
        mdiOpenInNew,
        mdiArrowLeft,
        mdiCircleSlice8,
        mdiPlayCircleOutline,
      },
    }
  },
}
</script>

<style lang="scss" scoped>
@import "@/styles/next-stop/components/map.scss";

.router-link-route {
  text-decoration: none;
}

.mgl-map-wrapper {
  height: 15rem;
  border-radius: 20px;
  overflow: hidden;
}

.table-basic-info-panel td {
  border-bottom: none !important;
}

.map-icon {
  background-color: white;
  border-radius: 50%;
  padding: 0;
  box-shadow: 0 5px 10px -4px rgba(94, 86, 105, 0.42);
}

.stop-full-annoucement {
  line-height: 2.5;
}

.v-chip.v-size--default {
  padding-top: 6px;
  padding-bottom: 6px;
  min-height: 32px;
  height: auto;
  white-space: normal;
}
</style>
